<template>
    <section class="multi-platform-payment" @click.stop="pickerclick">
        <div class="top-bar bg-white">
            <el-input v-model="formSearch.keyword" style="margin: 0 10px; width: 250px" placeholder="请输入关键字搜索"
                      size="small"/>
            <el-date-picker ref="startDateRef" v-model="formSearch.startDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            style="margin: 0 10px; width: 250px" size="small" placeholder="请选择开始日期"/>
            <el-date-picker ref="endDateRef" v-model="formSearch.endDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            style="margin: 0 10px; width: 250px" size="small" placeholder="请选择结束日期"/>
            <el-select ref="merchantRef" v-model="formSearch.merchant" size="small" placeholder="请选择商户号简称" style="margin: 0 10px;">
                <el-option v-for="item in serviceList" :key="item.id" :label="item.name" :value="item.subMerchantId"/>
            </el-select>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
            <el-button type="primary" size="small" @click="makeExport">导出数据</el-button>
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getOrderTableData" :columns="tableColumn"
                   :query="formSearch" tooltip-effect="light" :height="760" show-summary :summary-method="getSummaries"
        >
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </section>
</template>

<script>
import {tableColumn} from "./data";
import {makeExport} from "@/api/order";
import {downloadByData} from "@/utils/hooks/download";
import {timeFormat, numberFormat} from "@custom/index";
import {getServiceInfoList, getServiceOrderList} from "@/api/multi-platform-payment";

export default {
    name: "multi-platform-payment",
    data() {
        return {
            tableColumn,
            formSearch: {
                keyword: null,
                startDate: null,
                endDate: null,
                merchant: null,
            },
            serviceList: []
        };
    },
    async mounted() {
        this.getServiceList();
    },
    methods: {
        getOrderTableData(params) {
            return getServiceOrderList(params);
        },

        handleSearch() {
            // this.$refs.orderTableRef.pNumber = 1;
            this.$refs.orderTableRef.getTableData();
        },
        handleReset() {
            this.formSearch = {
                keyword: null,
            };
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },

        // rentExport(){
        //     const loading = this.$loading({lock: true, text: "正在导出请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
        //     let year = this.year;
        //     rentExport({year}).then(res=>{
        //         downloadByData({data: res, filename: `租金回笼${timeFormat(new Date(),'yyyy-MM-dd-HH-mm-ss')}.xls`});
        //         loading.close();
        //     }).catch(err => loading.close());
        // },

        makeExport() {
            const loading = this.$loading({
                lock: true,
                text: "正在导出请您稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });
            let {keyword, startDate, endDate, merchant} = this.formSearch;
            let paramData = {keyword, startDate, endDate, merchant};
            // console.log(paramData,111);
            makeExport(paramData).then(res => {
                downloadByData({data: res, filename: `支付流水${timeFormat(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.xls`});
                loading.close();
            }).catch(err => loading.close());
        },

        getSummaries(param) {
            const {columns, data} = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }

                if (index !== 7 && index !== 8) {
                    sums[index] = '';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) return prev + curr;
                        else return prev;
                    }, 0);
                    sums[index] = numberFormat(sums[index] / 100);
                } else sums[index] = '';

            });

            return sums;
        },

        getServiceList() {
            let that = this;
            getServiceInfoList().then(res => {
                let {list} = res;
                that.serviceList = list;
            });
        },
      // 2024-4-18-新增点击空白关闭组件
      pickerclick(e){
        console.log( this.itr)
        this.itr = !this.itr;
        if(this.itr){
          this.$refs['startDateRef'].hidePicker();
          this.$refs['endDateRef'].hidePicker();

        }
        this.$refs.merchantRef.blur()
        console.log(this.itr)
      },
      pickerclickitr(){
        this.itr = true;
        console.log(this.itr)
      },
    }
};
</script>


<style lang="scss" scoped>
.multi-platform-payment {
    padding: VH(15px) VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        justify-content: flex-start;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            /*white-space: nowrap;*/
            text-align: center;
        }
    }
}
</style>